import React from 'react'
import { Flex, Box } from 'rebass'
import { ArticlesContainer, Item, Title, ItemLink, ReleasedDate } from './styles'
import Img from 'gatsby-image'
import moment from 'moment'
import Stripe from '../Stripe'

const ArticleListing = (props) => {
  return (
    <ArticlesContainer w={1 / 1} pt={[10, null, 55]} pb={[50]} px={['5%']}>
      <Stripe full wide noheading />
      {
        props.items.map((item, key) => {
          const cleanedTitle = item.title.replace('&#038;', '&')
          const image = (item.sizes) ? <Img src={item.sizes.src} sizes={item.sizes} /> : null
          const title = <Title is='h2' fontSize={[6, 8, 9]} f={[3]} pt={25}>{ cleanedTitle }</Title>
          const uid = (item.attribution) ? `${item.title}:${item.attribution.source}:${key}` : `${item.title}:${key}`
          const formattedDate = moment(item.date).format('D MMM, YYYY')

          return (
            <Item w={[1 / 1]} py={30} px={50} key={uid}>
              { item.link &&
              <ItemLink to={item.link} >
                <Flex flexDirection={['column', null, 'row']}>
                  { image }
                  <Box w={[1 / 1, 1 / 1, 3 / 5]} ml={[null, null, 90]}>
                    { item.title && title }
                    <ReleasedDate mt={4} fontSize={0}>{formattedDate}</ReleasedDate>
                  </Box>
                </Flex>
              </ItemLink>
              }
              { !item.link &&
              <div>
                { image }
                { item.title && title }
              </div>
              }
            </Item>
          )
        })
      }
    </ArticlesContainer>
  )
}

export default ArticleListing
